const nookies = require('nookies');
const { useMediaQuery } = require('react-responsive');
const crypto = require('crypto-js');
const { IS_CAMPER } = require('./constants/system');
const { FAKE_LANG_MAP } = require('./constants/i18n');
const { md, xxl } = require('../styles/constants');
const { SIZE_GUIDES_ORDER, STANDARD_SIZE_GUIDE } = require('./constants/sizes');
const { GIFT_CARD_CODES, PREVOUCHER_FIXED_COLLECTIONS } = require('./constants/products');
const { ECI_CODE } = require('./constants/stores');

const { baseTranslations } = require('./translations');
const FilterMap = require('./filterMap');

const { SHA256 } = crypto;

// [OPT] string
const cleanRegex = (urlString) => {
  const urlArray = urlString.split('/');
  const stringToReturn = urlArray.map((item) => item.replace(/\(.*?\)/gi, ''));
  return stringToReturn.join('/');
};

// [OPT] filters
const sortByNumericKey = (arrayToSort, keyName = 'key') =>
  arrayToSort.sort((itemA, itemB) => {
    const parsedKeyA = Number.parseInt(itemA[keyName], 10);
    const parsedKeyB = Number.parseInt(itemB[keyName], 10);

    if (parsedKeyA > parsedKeyB) return 1;
    if (parsedKeyA < parsedKeyB) return -1;
    return 0;
  });

// [OPT] mandar a constants i18n
const Indexes = {
  MEN: 0,
  WOMEN: 1,
  KIDS: 2,
  GOODS: 3,
  ALL: 4,
};
// [OPT] mandar a constants i18n
const ProductIndexes = {
  SHOES: 0,
  BAGS: 1,
  APPAREL: 2,
  GOODS: 3,
  ALL: 4,
};

// [OPT] mandar a constants filters
const DEFAULT_LIMIT = 40;

// [OPT] mandar a constants i18n
const targetsMap = {
  M: Object.keys(baseTranslations.target).map((code) => baseTranslations.target[code][Indexes.MEN]),
  W: Object.keys(baseTranslations.target).map((code) => baseTranslations.target[code][Indexes.WOMEN]),
  K: Object.keys(baseTranslations.target).map((code) => baseTranslations.target[code][Indexes.KIDS]),
  G: Object.keys(baseTranslations.target).map((code) => baseTranslations.target[code][Indexes.GOODS]),
  X: '',
  C: '',
  WM: Object.keys(baseTranslations.target).map((code) => baseTranslations.target[code][Indexes.ALL]),
};

// [OPT] mandar constants i18n
const productsMap = {
  0: Object.keys(baseTranslations.product).map((code) => baseTranslations.product[code][ProductIndexes.ALL]),
  1: Object.keys(baseTranslations.product).map((code) => baseTranslations.product[code][ProductIndexes.SHOES]),
  2: Object.keys(baseTranslations.product).map((code) => baseTranslations.product[code][ProductIndexes.BAGS]),
  5: Object.keys(baseTranslations.product).map((code) => baseTranslations.product[code][ProductIndexes.APPAREL]),
  6: Object.keys(baseTranslations.product).map((code) => baseTranslations.product[code][ProductIndexes.GOODS]),
};

// [OPT] mandar a constants i18n
const mappers = {
  target: targetsMap,
  product: productsMap,
};

// [OPT] no se usa, borrar (?)
const getTargetProductsIndexes = (targetIndex) => {
  if (targetIndex === Indexes.MEN || targetIndex === Indexes.WOMEN) {
    return [ProductIndexes.SHOES, ProductIndexes.BAGS];
  }
  if (targetIndex === Indexes.KIDS) {
    return [ProductIndexes.SHOES];
  }
  return [];
};

// [OPT] i18n
const getLang = (langBase, defaultLang = 'es') => {
  try {
    return langBase.split('_')[0];
  } catch (error) {
    return defaultLang;
  }
};

const getRealLanguage = (language) => FAKE_LANG_MAP[language] ?? language;

// [OPT] borrar (?)
const getLastChildrenProps = (children) => {
  if (children === undefined) {
    return undefined;
  }
  if ('children' in children.props) {
    return getLastChildrenProps(children.props.children);
  }
  return children.props;
};

// [OPT] filters
const filterTransformer = {
  toQuery: (filters) => {
    // { sizes: [ {...} ] }
    const filtersByUrlParam = {};

    Object.keys(filters)
      .filter((item) => item !== 'first')
      .forEach((filterKey) => {
        const filterItems = filters[filterKey];

        filterItems.forEach((item) => {
          const { urlParam, value } = item;
          if (Object.keys(filtersByUrlParam).includes(urlParam)) {
            filtersByUrlParam[urlParam].push(value);
          } else {
            filtersByUrlParam[urlParam] = [value];
          }
        });
      });

    Object.keys(filtersByUrlParam).forEach((key) => {
      const values = filtersByUrlParam[key];
      filtersByUrlParam[key] = values.join(',');
    });

    return filtersByUrlParam;
  },
  encode: (filters) => {
    const filtersArr = [];

    Object.keys(filters).forEach((filterKey) => {
      filtersArr.push(`${filterKey}=${filters[filterKey]}`);
    });

    return filtersArr.join('&');
  },
  decode: (filters, availableFilters) => {
    const parsedFilters = {};

    Object.keys(filters).forEach((key) => {
      // filter.size
      let appliedIsAvailable = false;
      const values = filters[key].split(',');
      const [, trueFilterName] = key.split('.');

      let availableKey = null;

      Object.keys(FilterMap).forEach((filterType) => {
        const filterList = FilterMap[filterType];

        if (filterList.includes(trueFilterName)) {
          availableKey = filterType;
        }
      });

      const realValues = [];

      if (availableKey !== null) {
        const filterConfig = availableFilters[availableKey];

        // si un filtro que podemos aplicar no está
        // en available, por la lógica que ya tenemos
        // no podrá seguir usando ese filtro onScroll
        // o que lo pase el API o cambiamos esta lógica para
        // que lo siga aplicando de alguna forma

        if (filterConfig === undefined) {
          console.error('Applied filter is not in available.');
        } else {
          appliedIsAvailable = true;
          values.forEach((value) => {
            const configItem = filterConfig.filter((filterItem) => filterItem.key === value)[0];
            realValues.push({ ...configItem, value });
          });
        }
      }

      if (appliedIsAvailable) {
        parsedFilters[availableKey] = realValues;
      }
    });

    return parsedFilters;
  },
};

// [OPT] storage
const handleStorage = (method, ...payload) => {
  if (process.browser) {
    try {
      const value = localStorage[method](...payload);
      return value === null ? undefined : value;
    } catch (e) {
      console.error(e);
    }
  }
  return undefined;
};

// [OPT] dates
const getCurrentDateTime = () => {
  const today = new Date();
  const dateTime = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  return dateTime;
};

// [OPT] currency
function formatCurrency(currency) {
  // de momento hasta que quitemos todas las referencias a formatCurrency
  return currency;
}

// [OPT] currency
const withCurrency = (item, lang, currency) => `${item} ${formatCurrency(currency)}`;

// [OPT] dates
function timeSince(dateString) {
  const sufix = ' ago';
  const date = new Date(dateString);
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return `${Math.floor(interval)} years${sufix}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months${sufix}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days${sufix}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours${sufix}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes${sufix}`;
  }
  return `${Math.floor(seconds)} seconds${sufix}`;
}

// [OPT] borrar (?)
// eslint-disable-next-line no-sequences
const pick = (objectToSearch, keys) => {
  const resultObject = {};

  Object.keys(keys).forEach((item) => {
    resultObject[item] = objectToSearch[keys[item]];
  });

  return resultObject;
};

// [OPT] strings
const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

function setCookie(name, value, days, domain, secure, sameSite) {
  let expires = '';
  let strDomain = '';
  let secureStr = '';
  let sameSiteStr = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  if (domain) {
    strDomain = `; domain=${domain}`;
  }

  if (secure === true) {
    secureStr = '; Secure';
  }

  if (sameSite) {
    sameSiteStr = `; SameSite=${sameSite}`;
  }

  document.cookie = `${name}=${value || ''}${expires}${strDomain}; path=/${secureStr}${sameSiteStr}`;
}

// [OPT] cookies
function getCookie(name) {
  const nameEQ = `${name}=`;
  if (process.browser) {
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

// [OPT] cookies
function eraseCookie(name, domain) {
  if (domain) {
    document.cookie = `${name}=; Path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } else {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

// [OPT] number
const roundNumToTwoDecimals = (number) => {
  const roundedNumber = Math.round((number + Number.EPSILON) * 100) / 100;
  if (!Number.isNaN(roundedNumber)) {
    return roundedNumber;
  }
  return 0;
};

// [OPT] number
const areEqual = (a, b) => {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (let i = 0; i < aKeys.length; i++) {
    const keyValue = aKeys[i];
    const aValue = a[keyValue];
    const bValue = b[keyValue];

    if (aValue !== bValue) {
      return false;
    }
  }

  return true;
};

// [OPT] i18n
const getRealTarget = (target) => {
  let targetRealID = '';
  Object.keys(targetsMap).forEach((targetKey) => {
    if (targetsMap[targetKey].includes(target)) {
      targetRealID = targetKey;
    }
  });
  return targetRealID;
};

// [OPT] i18n
const getTarget = (targetId, langIndex) => {
  let target = '';
  if (targetsMap[targetId] !== null) {
    if (langIndex === undefined) {
      [target] = targetsMap[targetId];
    } else {
      target = targetsMap[targetId][langIndex];
    }
  }
  return target;
};

// [OPT] i18n
const getFamily = (familyId) => {
  let family = '';
  if (productsMap[familyId] !== null) {
    [family] = productsMap[familyId];
  }
  return family;
};

// [OPT] i18n
const getRealID = (key, value) => {
  let realID = '';
  const map = mappers[key];
  Object.keys(map).forEach((mapKey) => {
    if (map[mapKey].includes(value)) {
      realID = mapKey;
    }
  });
  return realID;
};

// [OPT] productos
const getNewPrices = (currents, news) => {
  currents.forEach((product, index) => {
    const { productId } = product;
    if (GIFT_CARD_CODES.includes(productId)) {
      product.prices = product.price;
    } else {
      const newProduct = news && news.length === currents.length ? news[index] : null;

      if (newProduct !== null) {
        product.prices = newProduct.prices;
      } else {
        product.prices = product.price;
      }
    }
  });

  return currents;
};

// [OPT] i18n
const getMarket = (locale) => {
  try {
    return locale.split('_')[1];
  } catch (error) {
    return 'ES';
  }
};

// [OPT] error
const consoleError = (message) => {
  console.error(message.toString());
};

// [OPT] constantes cookies
const camperAppName = 'camper21';
const nnormalAppName = 'nnormal';
const appname = IS_CAMPER ? camperAppName : nnormalAppName;

// [OPT] constantes cookies
const DEFAULT_COOKIES = {
  ALREADY_DETECTED: `${appname}_already_detected`,
  APP_IPAD: `${appname}_appIpad`,
  ASK_PHONE: `${appname}_askPhone`,
  AUTO_BONDS: 'aut_bonds',
  BAG_MARKET: `${appname}_bagMarket`,
  BAG: `${appname}_bag`,
  BAG_DATE: `${appname}_bag_date`,
  BONDS: 'bonds',
  NEWSLETTER_BONDS: `${appname}_nl_bonds`,
  CITY_DROPPOINT: `${appname}_city_droppoint`,
  CLIENT: `${appname}_clientUUID`,
  CMSERVICE: 'cmservice',
  COUNTRY_PRICE: `${appname}_country_price`,
  DETECTED_COUNTRY: 'x-country-detected',
  EMPLOYEE_ID: `${appname}_eId`,
  ERROR: `${appname}_error`,
  EXPIRATION_6H: 0.25, // 1/4 de dia, es decir, 6h
  EXPIRATION_24H: 1, // 1/4 de dia, es decir, 6h
  EXPRESS_SHOPPING_DST_ID: `${appname}_expressShoppingDstId`,
  GLOBAL_E: 'GlobalE_Data',
  I18N_ARRAY: `${appname}_i18n_array`,
  IS_CTR: `${appname}_isCtr`,
  LOCATOR: `${appname}_locator`,
  MEMBER_EMAIL: `${appname}_member_email`,
  ORDER_DETAILS: `${appname}_orderDetails`,
  PACKSTATION: `${appname}_pck`,
  PREVOUCHER: `${appname}_prevoucher`,
  SAP_ACTIVE: `${appname}_sap_active`,
  SELLIGENT_USRHASH: 'usrhash',
  SHIPMENT: `${appname}_shipment`,
  SHIPPING_DATA: `${appname}_shipping_data_cookie`,
  STORE: `${appname}_store`,
  STORE_DATA: `${appname}_store_data`,
  TEST_CAROUSEL: 'test_carousel_ia',
  TPV_EMPLOYEE_ID: `${appname}_tpvEmployeeId`,
  TPV_STORE_ID: `${appname}_tpvStoreId`,
  UNSUBSCRIBE_CAMPAIGN: 'unsubscribe_campaign',
  UNSUBSCRIBE_CHANNEL: 'unsubscribe_channel',
  UNSUBSCRIBE_SOURCE: 'unsubscribe_source',
  USER_ALREADY_SUBSCRIBED: `${appname}_isUserSub`,
  WORKSTATION: `${appname}_workstation`,
  ZIP: `${appname}_zip`,
  UNBXD_UID: 'unbxd.userId',
  MEMBERS_ONLY_SUBSCRIBED: 'userAlreadySubscribed',
};
const CAMPER_DEFAULT_COOKIES = {
  ...DEFAULT_COOKIES,
  LANG: '__camperLang',
  COUNTRY: '__camperCountry',
  CS_EMPLOYEE_ID: '__camperCS',
  DOMAIN: process.env.ENVIRONMENT === 'local' ? 'localhost' : '.camper.com',
  GB_COUNTRY: '__camperGBCountry',
  SHOE_REPAIR_DATA: '__shoeRepairData',
};
const NNORMAL_DEFAULT_COOKIES = {
  ...DEFAULT_COOKIES,
  LANG: '__nnormalLang',
  COUNTRY: '__nnormalCountry',
  CS_EMPLOYEE_ID: '__nnormalCS',
  DOMAIN: process.env.ENVIRONMENT === 'local' ? 'localhost' : '.nnormal.com',
  GB_COUNTRY: '__nnormalGBCountry',
  NNORMAL_WORLD_USER: '__nw_user_registered',
};
const COOKIES = IS_CAMPER ? CAMPER_DEFAULT_COOKIES : NNORMAL_DEFAULT_COOKIES;

// [OPT] products
let cookieDomain = undefined;
if (process.env.ENVIRONMENT !== 'local' && process.env.NEXT_PUBLIC_DOMAIN === 'camper') cookieDomain = '.camper.com';
if (process.env.ENVIRONMENT !== 'local' && process.env.NEXT_PUBLIC_DOMAIN === 'nnormal') cookieDomain = '.nnormal.com';

const formatProducts = (products) =>
  products.map(({ code, productId, size, price, camperoneOrderId, giftCard }) => ({
    id: productId || code,
    size,
    price: price.current,
    camperoneOrderId: camperoneOrderId || null,
    giftCard: giftCard || null,
  }));

// [OPT] mandar a constant ux
const emptyGridImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALAAAAEICAQAAABVULZ5AAABYElEQVR42u3QMREAAAgEID+50TWFiwcRyHRxKIIFC0awYMEIFiwYwYIRLFgwggULRrBgwYIFC0awYMEIFiwYwYIRLFgwggULRrBgwRIEC0awYMEIFiwYwYIRLFgwggULRrBgwQgWjGDBghEsWDCCBSNYsGAECxaMYMGCESwYwYIFI1iwYAQLRrBgwQgWLBjBggUjWDCCBQtGsGDBCBaMYMGCESxYMIIFC0awYAQLFoxgwYIRLBjBggUjWLBgBAsWjGDBCBYsGMGCBSNYMIIFC0awYMEIFiwYwYIRLFgwggULRrBgBAsWjGDBghEsWDCCBSNYsGAECxaMYMEIFiwYwYIFI1iwYAQLRrBgwQgWLBjBghEsWDCCBQtGsGDBCBaMYMGCESxYMIIFI1iwYAQLFoxgwYIRLBjBggUjWLBgBAtGsGDBCBYsGMGCBSNYMIIFC0awYMEIFixYsGDBCBb81QKwKosIMpTHUQAAAABJRU5ErkJggg==';

// [OPT] borrar
const configCamperONE = ({ lang, country }) => ({
  lng: lang,
  country,
});

// [OPT] sizes
const getBaseAvailableSizes = (targetSizeGuide, country) => {
  if (targetSizeGuide && Object.keys(targetSizeGuide).length > 0) {
    return [
      ...new Set(
        Object.keys(targetSizeGuide)
          .map((sizeRange) => targetSizeGuide[sizeRange].filter((sizes) => sizes.country === country)[0].size)
          .sort((a, b) => a - b),
      ),
    ];
  }
  return [];
};
// [OPT] sizes
const getBaseAvailableSizesSocks = (socksSizeGuide) => {
  const availableSizes = Object.keys(socksSizeGuide);
  const orderedSizes = SIZE_GUIDES_ORDER.filter((sizeLabel) => availableSizes.includes(sizeLabel)).map((label) => ({
    // sizeLabel: `${label} (${socksSizeGuide[label][0]}-${socksSizeGuide[label][socksSizeGuide[label].length - 1]})`, [TODO: De donde saldra esto ahora?]
    sizeLabel: `${label}`,
    value: label,
  }));
  return orderedSizes;
};

// [OPT] productos
const getRecent = () => {
  const data = handleStorage('getItem', 'recentItems');
  const result = data === undefined ? {} : JSON.parse(data);
  return result;
};

// [OPT] i18n
const getTargetIndex = (target) => {
  let targetIndex = null;
  Object.keys(baseTranslations.target).forEach((item) => {
    const translationsValues = baseTranslations.target[item];
    const tempIndex = translationsValues.indexOf(target);
    if (tempIndex !== -1) {
      targetIndex = tempIndex;
    }
  });
  return targetIndex;
};

// [OPT] number
function round(value, decimals = 3) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`)
    .toFixed(decimals)
    .replace('.', ',');
}

// [OPT] currency
const priceMap = {
  US: (price) => `${round(price, 2)} USD`,
  MX: (price) => (Number.isInteger(price) ? `${round(price, 0)} MXN` : `${round(price, 2)} MXN`),
};

// [OPT] currency
function roundAndFormatPrice(price, market, currency = null) {
  if (currency) {
    return Number.isInteger(price) ? `${round(price, 0)} ${currency}` : `${round(price, 2)} ${currency}`;
  }
  if (market in priceMap) {
    return priceMap[market](price);
  }
  return Number.isInteger(price) ? `${round(price, 0)}` : `${round(price, 2)}`;
}

// [OPT] currency
const formatShipmentCost = (cost, market, t, currency = null) => {
  if (cost === 0) {
    return t('ficha.producto', 'free');
  }
  return `${roundAndFormatPrice(cost, market, currency)}`;
};

// [OPT] cms
const replaceUndefinedKeysForNull = (obj) => {
  if (obj !== undefined && obj !== null) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        replaceUndefinedKeysForNull(obj[key]);
      } else if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          replaceUndefinedKeysForNull(obj[key][i]);
        }
      }
      if (obj[key] === undefined) {
        obj[key] = null;
      }
    });
  }
};

// [OPT] ux
const getSrcSet = (images, type = 'main') => {
  const srcSet = {};
  if (images && Array.isArray(images) && images.length) {
    for (let i = 0; i < images.length; i++) {
      srcSet[images[i].type] = images[i][type];
    }
    /* Monta algo asi
      {
        xs: 'url-image-xs.jpg'
        md: 'url-image-md.jpg'
        lg: 'url-image-lg.jpg'
        full: 'url-image-full.jpg'
      }
    */
  }
  return srcSet;
};

// [OPT] borrar (?)
const getCodeWithoutColor = (code) => {
  try {
    const codeWithoutColor = code.split('-').slice(0, 2).join('-');
    return codeWithoutColor;
  } catch (e) {
    console.error('Failed to get code without color. Returning code.');
    return code;
  }
};

// [OPT] ux
const getIsMobile = (width = md) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${width})`,
  });
  return isMobile;
};

// [OPT] ux
const getIsWideDesktop = (width = xxl) => {
  const isWideDesktop = useMediaQuery({
    query: `(min-width: ${width})`,
  });
  return isWideDesktop;
};

// [OPT] pagos
const handleAdyenFlow = ({ locale, ref, checkout, adyenData, onDetail, onFinish }) => {
  const postDetails = (state) => {
    onDetail({
      lang: locale,
      adyenData: state.data,
    }).then((response) => {
      const { adyenResponse } = response;
      const { resultCode, action } = adyenResponse;
      // Redirect | ChallengeShopper | IdentifyShopper
      const codes = ['Authorised', 'Refused', 'Cancelled', 'Error'];
      if (codes.includes(resultCode)) {
        onFinish(adyenResponse);
      } else {
        checkout
          .createFromAction(
            {
              ...action,
            },
            { size: '05', onAdditionalDetails: postDetails },
          )
          .mount(ref);
      }
    });
  };

  const config = { size: '05', onAdditionalDetails: postDetails };

  checkout.createFromAction({ ...adyenData.action }, config).mount(ref);
};

// [OPT] ux
function updateQuerystringParam(querystring, paramName, paramValue, defaultValue) {
  if (querystring.startsWith('?')) {
    querystring = querystring.substring(1);
  }
  const querystringList = querystring.split('&').filter((param) => param !== '' && !param.includes(`${paramName}=`));
  if (paramValue !== defaultValue) {
    querystringList.push(`${paramName}=${paramValue}`);
  }
  querystring = querystringList.join('&');
  window.history.replaceState({}, '', querystring.length > 0 ? `?${querystringList.join('&')}` : window.location.pathname);
}

// [OPT] faqs
function getIsActive(selected, id, isFirst = false, checker = () => true) {
  return ((selected === null && isFirst) || selected === id) && checker() ? 'active' : '';
}

// [OPT] borrar(?)
const loadsCamperONE = (src) => {
  let shouldUseGTM = false;

  if (src.includes('/assets-new/')) {
    shouldUseGTM = true;
  }

  return shouldUseGTM;
};

// [OPT] borrar (?)
const observeMutation = (target, config, callback) => {
  if (target !== undefined) {
    const observer = new MutationObserver(callback);
    observer.observe(target, config);
  } else {
    console.error('Bad mutation observer, target is', target);
  }
};

/**
 * Extrae datos del provider
 * TODO Crear un endpoint para hacer esto mismo en la API
 */
// [OPT] constante ux
const keyMapper = {
  addressLine1: 'Address',
  addressLine2: 'Address2',
  addressLine3: 'Address3',

  postalCode: 'Cp',
  city: 'City',
  stateOrRegion: 'State',
  countryCode: 'Country',
};

// [OPT] pagos
const extractDataFromProvider = (providerObj, mode) => {
  const { name: fullName, phoneNumber } = providerObj;

  const [name] = fullName.split(' ');
  let [, ...surnames] = fullName.split(' ');

  surnames = surnames.join(' ');

  const baseKey = mode;
  const objectToReturn =
    mode === 'delivery' ?
      {
        name,
        surnames,
        phone: phoneNumber.replace(/ /g, ''),
      }
    : {
        [`${baseKey}Name`]: name,
        [`${baseKey}Surnames`]: surnames,
      };

  Object.keys(keyMapper).forEach((key) => {
    const apiKey = keyMapper[key];
    objectToReturn[`${baseKey}${apiKey}`] = providerObj[key];
  });

  /*
  let objectToReturn = {
    name,
    surname,
    address,
    preffix,
    telephone,
    treatment: null,
    gender: null,
    city,
    country: countryCode,
    postalCode,
  };

  if (isBilling) {
    const oldObjectClone = { ...objectToReturn };
    objectToReturn = {};

    Object.keys(oldObjectClone).forEach((oldKey) => {
      objectToReturn[`billing-${oldKey}`] = oldObjectClone[oldKey];
    });
  } */

  return objectToReturn;
};

/**
 * Comprueba si la peticion es hecha por el navegador o hecha por NextJS (con <Link>)
 */
// [OPT] borrar
const isServerSideFromNavigator = (req) => req.url.indexOf('/_next/data/') === -1;

// [OPT] string
const isEmail = (string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(string);

// [OPT] string
const isMaterial = (searchString) => {
  let matchMaterial = false;
  if (/^(.{5})-(.{3})$/.test(searchString) || /^(.{7})-(.{3})$/.test(searchString)) {
    matchMaterial = true;
  }

  return matchMaterial;
};

// function that returns 'desktop' or 'mobile' depending on the user agent or null if the user agent is not defined
function getUserAgent(context) {
  try {
    const UA = context.req.headers['user-agent'];
    if (UA.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) {
      return 'mobile';
    }
    // checks for desktops and laptops
    if (UA.match(/Windows|Linux|Macintosh/i)) {
      return 'desktop';
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

const userAgentIsMobile = (context) => {
  try {
    const UA = context.req.headers['user-agent'];
    const isMobile = Boolean(UA.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
    return isMobile;
  } catch (e) {
    console.error(e);
    return false;
  }
};

// [OPT] constante pagos
const CREDIT_CARD_TYPES = ['AMEB', 'AMEY', 'DINB', 'DINY', 'MSCB', 'MSCY', 'JCBB', 'JCBY', 'VISB', 'VISY'];

// [OPT] constante pagos
const AMAZON_PAY_LOCALES = {
  ES: 'es_ES',
  DE: 'de_DE',
  FR: 'fr_FR',
  IT: 'it_IT',
  EN: 'en_GB',
};

// [OPT] pagos
const getAmazonPayLocale = (locale) => {
  const country = getMarket(locale);
  return AMAZON_PAY_LOCALES[country] && AMAZON_PAY_LOCALES[country] !== undefined ? AMAZON_PAY_LOCALES[country] : 'en_GB';
};

// Este método está pensado para usarlo con el mapa
// [OPT] number
const isArrayEqual = (a, b) => {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

// [OPT] constante shipping
const postalCodePattern = {
  DE: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
  ES: /^[0-9]{5}$/,
  US: /^[0-9]{5}$|^[0-9]{5}-[0-9]{4}$/,
  NL: /^[0-9]{4}\s[A-Z]{2}$/,
  CA: /^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1} [0-9]{1}[a-zA-Z]{1}[0-9]{1}$/,
  SK: /^[0-9]{3}\s[0-9]{2}$/,
  JP: /^[0-9]{7}$/,
  CZ: /^[0-9]{3}\s[0-9]{2}$/,
  PT: /^[0-9]{4}-[0-9]{3}$/,
  FR: /^[0-9]{5}$/,
  MC: /^[0-9]{5}$/,
  CH: /^[0-9]{4}$/,
  BR: /^[0-9]{5}-[0-9]{3}$/,
  KR: /^[0-9]{5}$/,
  GB: /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/,
  SE: /^[0-9]{3}\s[0-9]{2}$/,
  TR: /^[0-9]{5}$/,
  AU: /^[0-9]{4}$/,
  LT: /^[0-9]{5}$/,
  IL: /^[0-9]{7}$/,
  RU: /^\d{6}$/,
  IE: /^[ACDEFHKNPRTVWXY]{1}[0-9]{1}[0-9W]{1}[ -]?[0-9ACDEFHKNPRTVWXY]{4}$/,
  LV: /^[0-9]{4}$/,
  XI: /^((BT|bt)[0-9]{1,2}( [0-9]{1,2}[a-zA-Z]{2})?)$/,
};

// [OPT] constante network
const HEADERS = {
  REQUEST_ID: 'jb-request-id',
  USER_AGENT: 'user-agent',
};

/**
 *
 * @param {Object} ctx,
 * @returns Object
 */
// [OPT] network
const prepareHeadersFromContext = (ctx) => {
  try {
    if (ctx === null || ctx === undefined) {
      return null;
    }

    const { req, locale } = ctx;
    if (req === null || req === undefined) {
      return null;
    }

    const { cookies, headers } = req;

    let jbRequestId = null;
    let cookieClient = null;
    let clientUrl = null;
    let userAgent = null;

    if (headers !== null && headers !== undefined) {
      jbRequestId = headers[HEADERS.REQUEST_ID];
      if (jbRequestId === undefined) {
        jbRequestId = null;
      }
      userAgent = headers[HEADERS.USER_AGENT];
      if (userAgent === undefined) {
        userAgent = null;
      }
    }

    if (cookies !== null && cookies !== undefined) {
      cookieClient = cookies[COOKIES.CLIENT];
      if (cookieClient === undefined) {
        cookieClient = null;
      }
    }

    if (locale !== null && locale !== undefined && !['default', 'int'].includes(locale)) {
      let baseUrl = '';
      if (process !== null && process !== undefined) {
        baseUrl = process.env.ENVIRONMENT === 'local' ? 'http://localhost:3000' : process.env.INTEGRA_DOMAIN;
      }
      clientUrl = `${baseUrl}/${locale}${req.url}`;
    }

    // No devolvemos todos los headers, solo los que nos interesan para la API
    const returnHeaders = {
      'JB-REQUEST-ID': jbRequestId,
      Cookie: `${appname}_clientUUID=${cookieClient}`,
      'client-url': clientUrl,
      'User-Agent': userAgent,
    };

    return returnHeaders;
  } catch (e) {
    console.error(e);
    return null;
  }
};

// verifica que una url dada continene queryParams
// [OPT] borrar
const urlHaveQueryParams = (url) => url.indexOf('?') !== -1;

// [OPT] cookies
const handleOriginByCookie = () => {
  const cookies = {
    channel: 'channelLastClick',
    campaign: 'campaignLastClick',
  };
  const objToReturn = {};

  Object.keys(cookies).forEach((key) => {
    const item = cookies[key];
    const cookieValue = getCookie(item);

    if (cookieValue) {
      objToReturn[key] = cookieValue;
    }
  });

  return objToReturn;
};
// Obtiene la key de un objeto por su valor
// [OPT] borrar
const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

// tengo que hacer esto ya que esta función hará return si sap está inactivo pero sino no tiene que hacer nada
// [OPT] network
// eslint-disable-next-line consistent-return
const handleSapStatusSSR = (ctx, locale, response, shipmentCookie = '01') => {
  if (response !== undefined) {
    const { sapActive, extendedBlockSAP } = response;

    if (!sapActive && !extendedBlockSAP) {
      // can't pay
      return {
        redirect: {
          destination: `/${locale}/pedido_offline`,
          permanent: false,
        },
      };
    }
    nookies.setCookie(ctx, COOKIES.SHIPMENT, shipmentCookie, { path: '/', domain: COOKIES.DOMAIN });
  }
};

// [OPT] base64
const base64ToBlob = (base64String) => {
  const byteString = window.atob(base64String);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  return blob;
};

// [OPT] ux
function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

// [OPT] base64
const openBase64 = (title, base64Data) => {
  const blob = base64ToBlob(base64Data);
  const url = URL.createObjectURL(blob);
  const isIOS = iOS();
  const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
  if (isSafari || isIOS) {
    const downloadBlob = (fileName, blobData) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blobData);
      link.id = 'recipePdf';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    };
    downloadBlob('recipe.pdf', blob);
  } else {
    const newWindow = window.open(url, '_blank'); // por alguna razón ahora no encuentra newWindow en el scope del timeout
    setTimeout(() => {
      if (newWindow) {
        newWindow.document.title = title;
      }
    }, 10);
  }
};

// [OPT] ux
const autoOpenLink = (url) => {
  if (process.browser) {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    if (isSafari || iOS()) {
      document.getElementById('recipePdf').remove();
      const link = document.createElement('a');
      link.id = 'recipePdf';
      link.href = url;
      link.download = 'recipe.pdf';
      document.body.appendChild(link);
      link.click();
    } else {
      window.open(url, '_blank');
    }
  }
};

// [OPT] i18n
const checkLocale = (locale) => {
  const exceptions = {
    en_XI: 'en_GB',
  }; // diferenciado de checkMarket para no asumir el idioma

  let localeToReturn = locale;

  if (exceptions[locale]) {
    localeToReturn = exceptions[locale];
  }

  return localeToReturn;
};

// [OPT] i18n
const checkMarket = (market) => {
  const exceptions = {
    XI: 'GB',
  };

  let marketToReturn = market;

  if (exceptions[market]) {
    marketToReturn = exceptions[market];
  }

  return marketToReturn;
};

// [OPT] ux
const detectIOS = () => {
  if (process.browser) {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  return false;
};

// [OPT] number
const padNumber = (num, size) => {
  const protoString = `000000000${num}`;
  return protoString.substring(protoString.length - size);
};

// [OPT] network
const getHeader = (headerToFind) => {
  if (process.browser && window && window.document && window.document.location) {
    const emptyRequest = new XMLHttpRequest();
    emptyRequest.open('GET', document.location, false);
    emptyRequest.send(null);

    const currentHeaders = emptyRequest.getAllResponseHeaders().toLowerCase();
    const splitHeaders = currentHeaders.split('\r\n');

    for (let i = 0; i < splitHeaders.length; i++) {
      const header = splitHeaders[i];

      const [headerName, headerValue] = header.split(': ');

      if (headerName === headerToFind) {
        return headerValue;
      }
    }
  }

  return false;
};

// [OPT] base64
const stringToBase64 = (text) => Buffer.from(text, 'binary').toString('base64');
const decodeBase64 = (base64String) => Buffer.from(base64String, 'base64').toString('utf8');

// [OPT] constante sizes
const nnormalSizesMap = {
  M: {
    4: 'EU36⅔ - UK4',
    4.5: 'EU37⅓ - UK4½',
    5: 'EU38 - UK5',
    5.5: 'EU38⅔ - UK5½',
    6: 'EU39⅓ - UK6',
    6.5: 'EU40 - UK6½',
    7: 'EU40⅔ - UK7',
    7.5: 'EU41⅓ - UK7½',
    8: 'EU42 - UK8',
    8.5: 'EU42⅔ - UK8½',
    9: 'EU43⅓ - UK9',
    9.5: 'EU44 - UK9½',
    10: 'EU44⅔ - UK10',
    10.5: 'EU45⅓ - UK10½',
    11: 'EU46 - UK11',
    11.5: 'EU46⅔ - UK11½',
    12: 'EU47⅓ - UK12',
    13: 'EU48⅔ - UK13',
  },
  W: {
    4: 'EU36⅔ - UK4',
    4.5: 'EU37⅓ - UK4½',
    5: 'EU38 - UK5',
    5.5: 'EU38⅔ - UK5½',
    6: 'EU39⅓ - UK6',
    6.5: 'EU40 - UK6½',
    7: 'EU40⅔ - UK7',
    7.5: 'EU41⅓ - UK7½',
    8: 'EU42 - UK8',
    8.5: 'EU42⅔ - UK8½',
    9: 'EU43⅓ - UK9',
    9.5: 'EU44 - UK9½',
    10: 'EU44⅔ - UK10',
    10.5: 'EU45⅓ - UK10½',
    11: 'EU46 - UK11',
    11.5: 'EU46⅔ - UK11½',
    12: 'EU47⅓ - UK12',
    13: 'EU48⅔ - UK13',
  },
  AU: {
    4: 'US5.5 (W) - US4.5 (M) / UK4',
    4.5: 'US6 (W) - US5 (M) / UK4½',
    5: 'US6.5 (W) - US5.5 (M) / UK5',
    5.5: 'US7 (W) - US6 (M) / UK5½',
    6: 'US7.5 (W) - US6.5 (M) / UK6',
    6.5: 'US8 (W) - US7 (M) / UK6½',
    7: 'US8.5 (W) - US7.5 (M) / UK7',
    7.5: 'US9 (W) - US8 (M) / UK7½',
    8: 'US9.5 (W) - US8.5 (M) / UK8',
    8.5: 'US10 (W) - US9 (M) / UK8½',
    9: 'US10.5 (W) - US9.5 (M) / UK9',
    9.5: 'US11 (W) - US10 (M) / UK9½',
    10: 'US11.5 (W) - US10.5 (M) / UK10',
    10.5: 'US12 (W) - US11 (M) / UK10½',
    11: 'US12.5 (W) - US11.5 (M) / UK11',
    11.5: 'US13 (W) - US12 (M) / UK11½',
    12: 'US13.5 (W) - US12.5 (M) / UK12',
    13: 'US14.5 (W) - US13.5 (M) / UK13',
  },
  US: {
    4: 'US5.5 (W) - US4.5 (M)',
    4.5: 'US6 (W) - US5 (M)',
    5: 'US6.5 (W) - US5.5 (M)',
    5.5: 'US7 (W) - US6 (M)',
    6: 'US7.5 (W) - US6.5 (M)',
    6.5: 'US8 (W) - US7 (M)',
    7: 'US8.5 (W) - US7.5 (M)',
    7.5: 'US9 (W) - US8 (M)',
    8: 'US9.5 (W) - US8.5 (M)',
    8.5: 'US10 (W) - US9 (M)',
    9: 'US10.5 (W) - US9.5 (M)',
    9.5: 'US11 (W) - US10 (M)',
    10: 'US11.5 (W) - US10.5 (M)',
    10.5: 'US12 (W) - US11 (M)',
    11: 'US12.5 (W) - US11.5 (M)',
    11.5: 'US13 (W) - US12 (M)',
    12: 'US13.5 (W) - US12.5 (M)',
    13: 'US14.5 (W) - US13.5 (M)',
  },
  KR: {
    4: 'US5.5 (W) - US4.5 (M) / UK4',
    4.5: 'US6 (W) - US5 (M) / UK4½',
    5: 'US6.5 (W) - US5.5 (M) / UK5',
    5.5: 'US7 (W) - US6 (M) / UK5½',
    6: 'US7.5 (W) - US6.5 (M) / UK6',
    6.5: 'US8 (W) - US7 (M) / UK6½',
    7: 'US8.5 (W) - US7.5 (M) / UK7',
    7.5: 'US9 (W) - US8 (M) / UK7½',
    8: 'US9.5 (W) - US8.5 (M) / UK8',
    8.5: 'US10 (W) - US9 (M) / UK8½',
    9: 'US10.5 (W) - US9.5 (M) / UK9',
    9.5: 'US11 (W) - US10 (M) / UK9½',
    10: 'US11.5 (W) - US10.5 (M) / UK10',
    10.5: 'US12 (W) - US11 (M) / UK10½',
    11: 'US12.5 (W) - US11.5 (M) / UK11',
    11.5: 'US13 (W) - US12 (M) / UK11½',
    12: 'US13.5 (W) - US12.5 (M) / UK12',
    13: 'US14.5 (W) - US13.5 (M) / UK13',
  },
  BR: {
    4: 'BR 34.5 - EU36⅔',
    4.5: 'BR 35 - EU37⅓',
    5: 'BR 36 - EU38',
    5.5: 'BR 36.5 - EU38⅔',
    6: 'BR 37 - EU39⅓',
    6.5: 'BR 38 - EU40',
    7: 'BR 38.5 - EU40⅔',
    7.5: 'BR 39 - EU41⅓',
    8: 'BR 40 - EU42',
    8.5: 'BR 40.5 - EU42⅔',
    9: 'BR 41 - EU43⅓',
    9.5: 'BR 42 - EU44',
    10: 'BR 42.5 - EU44⅔',
    10.5: 'BR 43 - EU45⅓',
    11: 'BR 44 - EU46',
    11.5: 'BR 44.5 - EU46⅔',
    12: 'BR 45 - EU47⅓',
    13: 'BR 46 - EU48⅔',
  },
  JP: {
    4: 'JP22 - UK4',
    4.5: 'JP22.5 - UK4 ½',
    5: 'JP23 - UK5',
    5.5: 'JP23.5 - UK5 ½',
    6: 'JP24 - UK6',
    6.5: 'JP24.5 - UK6 ½',
    7: 'JP25 - UK7',
    7.5: 'JP25.5 - UK7 ½',
    8: 'JP26 - UK8',
    8.5: 'JP26.5 - UK8 ½',
    9: 'JP27 - UK9',
    9.5: 'JP27.5 - UK9 ½',
    10: 'JP28 - UK10',
    10.5: 'JP28.5 - UK10 ½',
    11: 'JP29 - UK11',
    11.5: 'JP29.5 - UK11 ½',
    12: 'JP30 - UK12',
    13: 'JP31 - UK13',
  },
};
const nnormalSizeMapCountries = Object.keys(nnormalSizesMap).filter((key) => !['M', 'W'].includes(key));

function isFakeECI(storeId) {
  if (typeof storeId !== 'string') return false;
  return ['T205', 'T250'].includes(storeId);
}

const getIsECI = (store) => store && store?.id && typeof store.id === 'string' && (store.id.startsWith(ECI_CODE) || isFakeECI(store.id));

// [OPT] ux
const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args);
    }, wait);
  };
};

const filterSizeGuideCountries = (market, guideItem) => {
  const newGuide = {};
  const sizeKeys = Object.keys(guideItem);

  for (let i = 0; i < sizeKeys.length; i++) {
    const key = sizeKeys[i];
    const measureItem = guideItem[key].filter((row) => STANDARD_SIZE_GUIDE.includes(row.country) || row.country === market);
    newGuide[key] = measureItem;
  }

  return newGuide;
};

// [OPT] network
const getHash = (str) =>
  new Promise((resolve) => {
    const hash = SHA256(str);

    resolve(hash);
  });

// [OPT] ux
const delayFunction = (executor, timeout) =>
  new Promise((resolve) => {
    setTimeout(() => {
      executor();
      resolve();
    }, timeout);
  });

function setCookieBonds(voucher) {
  eraseCookie(COOKIES.BONDS, COOKIES.DOMAIN);
  setCookie(COOKIES.BONDS, voucher, COOKIES.EXPIRATION_24H, COOKIES.DOMAIN);
}

function removeAllBondCookies() {
  eraseCookie(COOKIES.BONDS, COOKIES.DOMAIN);
  eraseCookie(COOKIES.AUTO_BONDS, COOKIES.DOMAIN);
  eraseCookie(COOKIES.SELLIGENT_USRHASH, COOKIES.DOMAIN);
}

const obtainVoucherPrices = (data, t, profile, filters, activatedPrevouchers, handleActivatePrevoucher, checkSize = false) => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    const urlCollection = searchParams.get('filter.collection');

    let collections = [];

    if (filters?.collections) {
      collections = [...filters.collections];
    }

    if (urlCollection) {
      collections = [
        {
          key: urlCollection,
          label: urlCollection,
          value: urlCollection,
          urlParam: 'filter.collection',
        },
        ...collections,
      ];
    }

    let prevoucher = getCookie(COOKIES.PREVOUCHER) || false;
    let voucher = getCookie(COOKIES.BONDS) || false;
    let voucherID = null;
    const userHashInUrl = searchParams.get('usrhash');
    const userHash = getCookie(COOKIES.SELLIGENT_USRHASH) || userHashInUrl;
    const voucherData = data?.voucherPrices;
    const lang = getLang(profile);
    const market = getMarket(profile);
    const encodedURL = encodeURIComponent(window?.location?.href);
    const selectedSize = window.selectedSizeForVoucher;

    // si no hay voucher
    //  sort en base a lowestDiscount
    //  mostramos mensaje verde
    // si hay voucher
    //  si coincide el md5 le pisamos prices con el de voucherPrices
    //  si no
    //   sort en base a lowestDiscount
    //   mostramos mensaje verde

    if (voucherData && Object.keys(voucherData).length > 0) {
      let voucherInfo = null;
      let voucherMessage = null;
      let displayMessage = false;
      let isPrevoucher = false;
      const anyNormalVoucher = Object.keys(voucherData).some((item) => voucherData[item]?.preLaunch === false);
      const [firstVoucher] = Object.keys(voucherData);
      const firstVoucherData = voucherData[firstVoucher];
      const isFirstVoucherPreVoucher = firstVoucherData?.preLaunch || false;

      if (userHash) {
        if (!isFirstVoucherPreVoucher) {
          voucher = firstVoucher;
          setCookieBonds(firstVoucher);
        }
      }

      if (isFirstVoucherPreVoucher) {
        // comprobar collections
        let activated = false;

        if (activatedPrevouchers?.length > 0) {
          if (activatedPrevouchers.includes(firstVoucher)) {
            prevoucher = firstVoucher;
            activated = true;
          }
        }

        let prevoucherInCollection = activated;

        if (!activated) {
          const { voucherColIds } = firstVoucherData;
          const collectionsToActivate = [...voucherColIds, ...PREVOUCHER_FIXED_COLLECTIONS];

          // comprobar también collections constantes
          const collectionIdInFilters = collections.find((item) => collectionsToActivate.includes(item.value));

          if (collectionIdInFilters) {
            prevoucherInCollection = true;
            handleActivatePrevoucher(prevoucher);
          }
        }

        if (prevoucherInCollection) {
          prevoucher = firstVoucher;
          setCookie(COOKIES.PREVOUCHER, firstVoucher, COOKIES.EXPIRATION_24H, COOKIES.DOMAIN);
        }
      }

      if (voucher) {
        if (Object.keys(voucherData).includes(voucher)) {
          voucherID = voucher;
          voucherInfo = voucherData[voucher];
        } else {
          voucherInfo = voucherData[firstVoucher];
          displayMessage = true;
        }
      } else if (prevoucher || (activatedPrevouchers?.length > 0 && isFirstVoucherPreVoucher)) {
        let activated = false;

        if (activatedPrevouchers?.length > 0) {
          if (activatedPrevouchers.includes(firstVoucher)) {
            prevoucher = firstVoucher;
            activated = true;
          }
        }

        if (firstVoucher === prevoucher) {
          voucherID = prevoucher;
          isPrevoucher = true;
          voucherInfo = voucherData[prevoucher];
          displayMessage = true;

          let prevoucherInCollection = activated;

          if (!activated) {
            const { voucherColIds } = voucherInfo;
            const collectionsToActivate = [...voucherColIds, ...PREVOUCHER_FIXED_COLLECTIONS];
            const collectionIdInFilters = collections.find((item) => collectionsToActivate.includes(item.value));

            if (collectionIdInFilters) {
              prevoucherInCollection = true;
              handleActivatePrevoucher(prevoucher);
            }
          }

          if (prevoucherInCollection === false) {
            voucherInfo = null;
            voucherMessage = null;
            displayMessage = false;
            isPrevoucher = false;
          }
        } else {
          displayMessage = true;

          isPrevoucher = !anyNormalVoucher;
        }
      } else {
        displayMessage = anyNormalVoucher;
        voucherInfo = anyNormalVoucher ? voucherData[firstVoucher] : null;
        isPrevoucher = !anyNormalVoucher;
      }

      if (displayMessage) {
        if (voucherInfo === null) {
          // no debería poder ocurrir si ocurre es un error
          console.error('[ERROR] - Trying to display a message for voucher when there is no voucher info.');
          console.log('Using first voucher in data.');
          voucherInfo = firstVoucherData;
        }
        const { currentFormated: vPrice, discount: vDiscount, pricePerSize, voucherType } = voucherInfo;

        let pricePerSizeLabel = pricePerSize ? `${voucherInfo?.priceDiscountFromFormatted} - ${voucherInfo?.priceDiscountToFormatted}` : '';
        if (pricePerSize && voucherInfo.voucherRangePrices) {
          pricePerSizeLabel = `${voucherInfo.voucherRangePrices.priceFromFormatted} - ${voucherInfo.voucherRangePrices.priceToFormatted}`;
        }

        if (checkSize && selectedSize) {
          const voucherPrices = data?.sizes?.find((item) => item.value === selectedSize)?.voucherPrices;

          if (!voucherPrices) {
            console.error('No voucherPrices per size for product with voucherPrices.');
          } else {
            pricePerSizeLabel = voucherPrices?.[voucherID]?.currentFormated;
          }
        }
        if (!userHash) {
          const messageKey = isPrevoucher ? 'prevoucher.price.message' : `voucher.price.message.${voucherType}`;
          voucherMessage = t('listado.productos', messageKey, '*** <TAG price> (<TAG discount>% OFF) for members. Subscribe')
            .replace('<TAG price>', pricePerSize ? pricePerSizeLabel : vPrice)
            .replace('<TAG discount>', vDiscount)
            .replace('<TAG URL ENCODED>', encodedURL)
            .replace('<TAG COUNTRY_CODE UPPER>', market)
            .replace('<TAG LANGUAGE_CODE LOWER>', lang);
        } else {
          // si está subscrito y es voucher nunca llega aquí porque tiene displayMessage false
          // si llega aquí con prevoucher es que está activado
          const messageKey = 'prevoucher.price.message.subscribed';
          voucherMessage = t('listado.productos', messageKey, '*** <TAG price> (<TAG discount>% OFF) during promo.')
            .replace('<TAG price>', pricePerSize ? pricePerSizeLabel : vPrice)
            .replace('<TAG discount>', vDiscount)
            .replace('<TAG URL ENCODED>', encodedURL)
            .replace('<TAG COUNTRY_CODE UPPER>', market)
            .replace('<TAG LANGUAGE_CODE LOWER>', lang)
            .replace('<TAG USR_HASH>', userHash);
        }
      }

      if (displayMessage === false && !isPrevoucher && voucherInfo) {
        setCookie(COOKIES.AUTO_BONDS, '1', COOKIES.EXPIRATION_24H, COOKIES.DOMAIN);
        const { voucherType } = voucherInfo;
        const voucherTypeToUseInLabel = data?.prices?.discount !== undefined ? voucherType : 'original.price';
        voucherInfo.appliedLabel = t('listado.productos', `voucher.price.message.${voucherTypeToUseInLabel}.applied`);

        if (voucherTypeToUseInLabel === 'original.price') {
          // pintado antiguo
          voucherInfo.discountStr = voucherInfo?.discountStr?.split('-')?.length < 2 ? `-${voucherInfo.discountStr}` : voucherInfo.discountStr;
        }

        if (voucherType === 'final.price' && data?.prices?.discount !== undefined) {
          voucherInfo.discountStr = `-${data?.prices?.discount}%`;
          voucherInfo.appliedLabel = voucherInfo.appliedLabel.replace('<TAG discount>', voucherInfo?.discount);
        }
      }

      if (voucherInfo) {
        if (voucherInfo?.appliedLabel?.includes('<TAG discount>') && voucherInfo?.discount?.length > 0) {
          // caso raro en el buscador
          voucherInfo.appliedLabel = voucherInfo.appliedLabel.replace('<TAG discount>', voucherInfo?.discount);
        }
        return { message: voucherMessage, info: voucherInfo };
      }
    }

    return null;
  }

  return null;
};

const hasAnyVoucherApplied = (products, ...params) =>
  products.some((item) => {
    const voucherPrices = obtainVoucherPrices(item, ...params);

    if (voucherPrices !== null && voucherPrices?.message === null) {
      return voucherPrices;
    }

    return false;
  });

// [OPT] ux
const getIsMobileSSR = (context) => {
  const userAgent = context.req.headers['user-agent'];
  const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|webOS|Opera Mini/.test(userAgent);

  return isMobile;
};

// LO METO EN HELPERS PARA QUE NO SE CHOQUE CON LAS CONSTANTS SEPARADAS
// PERO DEBERÍA IR A CONSTANTS
const CLOSE_SELLIGENT_POPUP = 'close-selligent-popup';

const getGTMStorage = () => {
  const currentList = handleStorage('getItem', 'gtmCurrentList');
  const currentListUrl = handleStorage('getItem', 'gtmCurrentListUrl');
  const itemsWithList = handleStorage('getItem', 'gtmItemsWithList');

  const currentListSplit = currentList ? currentList.split('|') : '';

  const currentListId = currentList && Array.isArray(currentListSplit) ? currentListSplit[0] : '';
  const currentListName = currentList && Array.isArray(currentListSplit) && currentList.length > 1 ? currentListSplit[1] : '';

  const lastVisitedUrl = document?.referrer ? document?.referrer.split('?')[0] : null;
  const gtmIndex = handleStorage('getItem', 'gtmCurrentItemIndex');
  const gtmCurrency = handleStorage('getItem', 'gtmCurrency');
  const itemListName = currentListUrl === lastVisitedUrl ? currentListName : '';
  const itemListId = currentListUrl === lastVisitedUrl && currentList ? currentListId.replaceAll(' ', '_') : '';

  return {
    gtmIndex,
    gtmCurrency,
    itemListName,
    itemListId,
    itemsWithList,
  };
};

const getFixedPrice = (market, profileData, currentPrice) => {
  let fixedPrice = undefined;

  if (profileData?.config && profileData?.config?.globale_fixed_price === true) {
    fixedPrice = `{"${market}": ${currentPrice}}`;
  }

  return fixedPrice;
};

const getAppliedDiscount = (prices) => {
  try {
    if (prices?.current && prices?.discount !== 0) {
      const discountDefault = typeof prices?.previous === 'number' ? prices.previous - prices.current : 0;

      return prices?.discountLocal || discountDefault;
    }
  } catch (e) {
    console.error(e);
  }

  return 0;
};

const getCountryLanguages = (countries, countryToSend) => {
  let defaultLanguage = 'en';

  try {
    const countryToSendLanguages = countries.filter((item) => item.id === countryToSend)[0]?.languages;

    if (countryToSendLanguages && countryToSendLanguages?.length > 0) {
      const filteredLanguage = countryToSendLanguages.filter((item) => item.default === true);

      if (filteredLanguage?.length > 0) {
        [{ id: defaultLanguage }] = filteredLanguage;
      }
    } else {
      const errorCountry = countries.filter((item) => item.id === countryToSend)[0];
      console.error('No hay idiomas para el país al que queremos enviar.', errorCountry);
    }
  } catch (e) {
    console.error(e);
  }

  return defaultLanguage;
};

const changeLocaleInUrl = (newLocale) => {
  try {
    const searchURL = new URLSearchParams(window.location.search);
    const [, , ...pathname] = window.location.pathname.split('/');
    const newPathname = `${newLocale}/${pathname.join('/')}`;

    window.location.assign(`${window.location.origin}/${newPathname}?${searchURL.toString()}`);
  } catch (e) {
    console.error(e);
  }
};

function deleteEmptyKeys(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key].trim() === '') {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      deleteEmptyKeys(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item) => {
        if (typeof item === 'object') {
          deleteEmptyKeys(item);
        }
      });
      if (obj[key].length === 0) {
        delete obj[key];
      }
    }
  }
}

function isWrongEmail(email) {
  if (!email) {
    return true;
  }
  return [
    '@gnail.com',
    '@gmail.con',
    '@hotnail.com',
    '@gmial.com',
    '@oultook.com',
    '@outbook.com',
    '@otlook.com',
    '@outlok.com',
    '@htmail.com',
    '@hitmail.com',
    '@hotmail.con',
    '@gmaiil.com',
    '@gmil.com',
    '@gmai.com',
    '@g.mail.com',
    '@hotmial.com',
    '@gmaiul.com',
    '@hotmil.com',
    '@gamail.com',
    '@oulook.com',
    '@gemail.com',
    '@gmail.comm',
    '@gmail.es',
    '@hotmail.cm',
    '@HTMAIL.COM',
    '@hormail.com',
    '@hotmaill.com',
    '@yhoo.com',
    '@gmIl.com',
    '@gmaail.com',
    '@homai.com',
    '@hotrmail.com',
    '@terra.es',
    '@hootmail.es',
    '@iclound.com',
    '@yahho.es',
    '@homail.es',
  ].some((wrongEmail) => email.trim().endsWith(wrongEmail));
}

module.exports = {
  cleanRegex,
  getLastChildrenProps,
  getLang,
  handleStorage,
  withCurrency,
  targetsMap,
  getTargetProductsIndexes,
  getCurrentDateTime,
  roundAndFormatPrice,
  formatCurrency,
  timeSince,
  pick,
  capitalize,
  setCookie,
  getCookie,
  eraseCookie,
  formatShipmentCost,
  roundNumToTwoDecimals,
  getRealTarget,
  getMarket,
  COOKIES,
  consoleError,
  filterTransformer,
  DEFAULT_LIMIT,
  formatProducts,
  getRealID,
  getNewPrices,
  emptyGridImg,
  configCamperONE,
  getBaseAvailableSizes,
  getRecent,
  getTargetIndex,
  round,
  replaceUndefinedKeysForNull,
  getSrcSet,
  getCodeWithoutColor,
  getIsMobile,
  handleAdyenFlow,
  getIsWideDesktop,
  getBaseAvailableSizesSocks,
  updateQuerystringParam,
  getFamily,
  getTarget,
  areEqual,
  getIsActive,
  loadsCamperONE,
  observeMutation,
  isServerSideFromNavigator,
  isEmail,
  isMaterial,
  userAgentIsMobile,
  CREDIT_CARD_TYPES,
  extractDataFromProvider,
  getAmazonPayLocale,
  isArrayEqual,
  postalCodePattern,
  prepareHeadersFromContext,
  urlHaveQueryParams,
  handleOriginByCookie,
  getKeyByValue,
  sortByNumericKey,
  handleSapStatusSSR,
  openBase64,
  stringToBase64,
  checkLocale,
  checkMarket,
  detectIOS,
  padNumber,
  getHeader,
  autoOpenLink,
  nnormalSizesMap,
  nnormalSizeMapCountries,
  getIsECI,
  debounce,
  getRealLanguage,
  filterSizeGuideCountries,
  delayFunction,
  getHash,
  decodeBase64,
  obtainVoucherPrices,
  hasAnyVoucherApplied,
  getIsMobileSSR,
  CLOSE_SELLIGENT_POPUP,
  getGTMStorage,
  setCookieBonds,
  getUserAgent,
  getFixedPrice,
  isFakeECI,
  getAppliedDiscount,
  cookieDomain,
  getCountryLanguages,
  changeLocaleInUrl,
  removeAllBondCookies,
  deleteEmptyKeys,
  isWrongEmail,
};
